.h-pass-genjutsu {

	&--party {
		&:before {
			content: '\e804';
		}
	}

	&--battlefield {
		&:before {
			content: '\e878';
		}
	}

	&--ninja {
		&:before {
			content: '\e817';
		}
	}

	&--copy {
		&:before {
			content: '\e83c';
		}
	}

	&--learn {
		&:before {
			content: '\e99c';
		}
	}
}