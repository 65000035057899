.h-text-shadow {

	&--2 {
		text-shadow: -1px -1px 0 #000,
					1px -1px 0 #000,
					-1px 1px 0 #000,
					1px 1px 0 #000,
					1px 2px 0 #000;
	}

	&--3 {
		text-shadow: 1px 1px 0 #000,
					1px 2px 0 #000,
					-1px 0px 0 #000,
					0 1px 0 #000,
					-1px 2px 0 #000,
					-1px -1px 0 #000,
					1px -1px 0 #000,
					-1px 1px 0 #000;
	}

	&--4 {
		text-shadow: 4px 1px 0 #000,
					3px 4px 0 #000,
					-2px -0px 0 #000,
					-0px 4px 0 #000,
					-1px 3px 0 #000,
					-2px -2px 0 #000,
					2px -2px 0 #000,
					-2px 2px 0 #000,
					2px 2px 0 #000,
					2px -2px 0 #000;
	}
}