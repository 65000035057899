.h-inline-flex {
	display: inline-flex !important;
}

.h-hidden {
	visibility: hidden !important;
}

.h-margin-box-m {
	margin: 10px;
}

.h-margin-box-l {
	margin: 20px;
}

.h-margin-top-m {
	margin-top: 10px;
}

.h-margin-top-l {
	margin-top: 25px;
}

.h-margin-top-xl {
	margin-top: 50px;
}