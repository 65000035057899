.h-icon {
	&:before {
		@extend %font-nm;
	}

	&--reroll {
		&:before  {
			content: '\e827';
		}
	}

	&--crit {
		&:before  {
			content: '\e823';
		}
	}

	&--duel {
		&:before  {
			content: '\e830';
		}
	}

	&--absorb {
		&:before  {
			content: '\e852';
		}
	}

	&--guard {
		&:before  {
			content: '\e85d';
		}
	}

	&--death {
		&:before  {
			content: '\e81c';
		}
	}

	&--penalty {
		&:before  {
			content: '\e834';
		}
	}

	&--up {
		&:before  {
			content: '\e846';
		}
	}

	&--tai {
		&:before  {
			content: '\e85d';
		}
	}

	&--nin {
		&:before  {
			content: '\e852';
		}
	}

	&--buki {
		&:before  {
			content: '\e83b';
		}
	}

	&--sta {
		&:before  {
			content: '\e85a';
		}
	}

	&--ele {
		&:before  {
			content: '\e829';
		}
	}

	&--gen {
		&:before  {
			content: '\e800';
		}
	}
}