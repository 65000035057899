/**
 * Variables.
 */
$item: '.c-item';
$tooltip: '.m-tooltip';





/**
 * Helpers.
 */
.h-item {
	> .a-item-icon {
		&:before {
			@extend %font-nm;

			content: '';
		}
	}
}





/**
 * Default colors.
 */
.h-item-default,
.h-item-item-1 {
	> .a-item-icon {
		border-color: #73583c #43301e #43301e #73583c;
		color: #a68b69;
		background-color: #513b26;
	}

	> .a-item-name {
		color: #a68b69;
	}


	// component
	@extend %h-item-default;
}

// placeholder
%h-item-default {
	&.c-item {
		border-color: #795936;
		background-color: #3f2a19;


		&:after {
			border-color: #2b1d11;
		}
	}


	// component
	#{$item}__icon {
		&:after {
			border-color: #2b1d11;
		}
	}

	// component
	#{$tooltip}__outer {
		border-color: #6a4e32;
	}
}





/**
 * Abilities.
 */
.h-item-ability-1 {
	> .a-item-icon {
		border-color: #73583c #43301e #43301e #73583c;
		color: #a68b69;
		background-color: #513b26;


		&:before {
			content: '\e83f';
		}
	}

	> .a-item-name {
		color: #a68b69;
	}


	// component
	#{$tooltip}__outer {
		border-color: #6a4e32;
	}
}


.h-item-ability-2 {
	> .a-item-icon {
		border-color: #73583c #43301e #43301e #73583c;
		color: #B48142;
		background-color: #513517;


		&:before {
			content: '\e848';
		}
	}

	> .a-item-name {
		color: #B48142;
	}


	// component
	#{$tooltip}__outer {
		border-color: #846240;
	}
}





/**
 * Legendary Weapons.
 */
.h-item-lw-1 {
	> .a-item-icon {
		border-color: #af8840 #654611 #654611 #af8840;
		color: #ffc02a;
		background-color: #875e17;
	}

	> .a-item-name {
		color: #ffb400;
	}


	// component
	&.c-item {
		border-color: #795936;
		background-color: #3f2a19;


		&:after {
			border-color: #2b1d11;
		}
	}

	// component
	#{$item}__icon {
		&:after {
			border-color: #2b1d11;
		}
	}

	// component
	#{$tooltip}__outer {
		border-color: #ae7b00;
	}
}


.h-item-lw-2 {
	> .a-item-icon {
		border-color: #af8840 #654611 #654611 #af8840;
		color: #ffc02a;
		background-color: #875e17;
	}

	> .a-item-name {
		color: #ffb400;
	}


	// component
	&.c-item {
		border-color: #795936;
		background-color: #3f2a19;


		&:after {
			border-color: #2b1d11;
		}
	}

	// component
	#{$item}__icon {
		&:after {
			border-color: #2b1d11;
		}
	}

	// component
	#{$tooltip}__outer {
		border-color: #ae7b00;
	}
}


.h-item-lw-nm1 {
	> .a-item-icon {
		border-color: #bd7b00 #6e3d00 #6e3d00 #bd7b00;
		color: #ffac00;
		background-color: #945200;
	}

	> .a-item-name {
		color: #ff9d00;
	}


	// component
	&.c-item {
		border-color: #795936;
		background-color: #3f2a19;


		&:after {
			border-color: #2b1d11;
		}

		&.-border-colored {
			border-color: #834f00;
		}
	}

	// component
	#{$item}__icon {
		&:after {
			border-color: #2b1d11;
		}
	}

	// component
	#{$tooltip}__outer {
		border-color: #be6c00;
	}
}


// TODO
.h-item-lw-wh1 {
	> .a-item-icon {
		border-color: #bdad8f #6e6253 #6e6253 #bdad8f;
		color: #fde9c1;
		background-color: #857664;
	}

	> .a-item-name {
		color: #fbe3bd;
	}


	// component
	&.c-item {
		border-color: #795936;
		background-color: #3f2a19;


		&:after {
			border-color: #2b1d11;
		}

		&.-border-colored {
			border-color: #726556;
		}
	}

	// component
	#{$item}__icon {
		&:after {
			border-color: #2b1d11;
		}
	}

	// component
	#{$tooltip}__outer {
		border-color: #c3ad91;
	}
}





/**
 * Bloodlines.
 */
.h-item-bloodline-none {
	> .a-item-icon {
		border-color: #74554c #472f2f #472f2f #74554c;
		color: #7a5353;
		background-color: #563939;
	}

	> .a-item-name {
		color: #7a5353;
	}


	// component
	&#{$item} {
		border-color: #724949;
		background-color: #312319;


		&:after {
			border-color: #2b1d11;
		}
	}

	// component
	#{$item}__icon {
		&:after {
			border-color: #2b1d11;
		}
	}

	// component
	#{$tooltip}__outer {
		border-color: #ab6161;
	}
}

.h-item-bloodline-1 {
	> .a-item-icon {
		border-color: #925757 #4c2a2a #4c2a2a #925757;
		color: #ff9797;
		background-color: #5b3333;
	}

	> .a-item-name {
		color: #ff9797;


		em {
			color: #f9b6b6;
		}
	}


	// component
	&#{$item} {
		border-color: #795936;
		background-color: #3f2a19;


		&:after {
			border-color: #2b1d11;
		}


		&.-border-colored {
			border-color: #ab6161;
		}
	}

	// component
	#{$item}__icon {
		&:after {
			border-color: #2b1d11;
		}
	}

	// component
	#{$tooltip}__outer {
		border-color: #ab6161;
	}
}





/**
 * Combos.
 */
.h-item-combo-1 {

	> .a-item-icon {
		border-color: #579263 #223f26 #223f26 #579263;
		color: #9cd9b8;
		background-color: #335a37;


		&:before {
			content: '\e825';
		}
	}

	> .a-item-name {
		color: #9cd9b8;
	}


	// component
	&#{$item} {
		border-color: #55745B;
		background-color: #242823;


		&:after {
			border-color: #191b18;
		}


		&.-border-colored {
			border-color: #55745B;
		}
	}

	// component
	#{$item}__icon {
		&:after {
			border-color: #191b18;
		}
	}


	// component
	#{$tooltip}__outer {
		border-color: #4b5d4e;
	}
}





/**
 * Genjutsus
 */
.h-item-genjutsu {

	> .a-item-icon {
		border-color: #7da8cc #33445a #33445a #7da8cc;
		color: #97d3ff;
		background-color: #477dab;


		&:before {
			content: '\e800';
		}
	}

	> .a-item-name {
		color: #97d3ff;
	}


	// component
	&#{$item} {
		&.-border-colored {
			border-color: #79a4d0;
		}
	}


	// component
	#{$tooltip}__outer {
		border-color: #79a4d0;
	}
}





/**
 * Material.
 */
.h-item-material-1 {

	> .a-item-icon {
		border-color: #777777 #484848 #484848 #777777;
		color: #bdbdbd;
		background-color: #545454;


		&:before {
			content: '\e947';
		}
	}

	> .a-item-name {
		color: #bdbdbd;
	}


	// component
	&#{$item} {
		border-color: #636363;
		background-color: #2f2f2f;


		&:after {
			border-color: #1f1f1f;
		}


		&.-border-colored {
			border-color: #838383;
		}
	}

	// component
	#{$item}__icon {
		&:after {
			border-color: #1f1f1f;
		}
	}

	#{$item}__pic {
		background-color: #1f1f1f;
	}

	// component
	#{$tooltip}__outer {
		border-color: #636363;
	}
}





/**
 * Disabled status.
 */
.h-item-disabled {
	> .a-item-icon {
		border-color: #675242 #332820 #332820 #675242;
		color: #a68b69;
		background-color: #3d3027;
	}

	> .a-item-name {
		color: #a89676;
	}


	// component
	&#{$item} {
		border-color: #423426;
		background-color: #312319;


		&:after {
			border-color: #1d140c;
		}
	}

	// component
	#{$item}__icon {
		&:after {
			border-color: #1d140c;
		}
	}
}

.h-item-disabled-2 {
	> .a-item-icon {
		border-color: #77583F #3A2E25 #3A2E25 #77583F;
		color: #a89676;
		background-color: #573F2E;
	}

	> .a-item-name {
		color: #a89676;
	}


	// component
	&#{$item} {
		border-color: #69513E;
		background-color: #4D3827;


		&:before {
			border-color: #332016;
		}

		&:after {
			border-color: #3e2e26;
		}
	}

	// component
	#{$item}__icon {
		&:after {
			border-color: #3e2e26;
		}
	}
}





/**
 * Icons inside items.
 */
.h-item-icon {

	&--dagger-1 {
		> .a-item-icon {
			&:before {
				content: '\e93f';
			}
		}
	}

	&--dagger-2 {
		> .a-item-icon {
			&:before {
				content: '\e926';
			}
		}
	}

	&--dagger-3 {
		> .a-item-icon {
			&:before {
				content: '\e943';
			}
		}
	}

	&--dagger-4 {
		> .a-item-icon {
			&:before {
				content: '\e961';
			}
		}
	}

	&--shard-1 {
		> .a-item-icon {
			&:before {
				content: '\e84a';
			}
		}
	}

	&--pill-1 {
		> .a-item-icon {
			&:before {
				content: '\e936';
			}
		}
	}

	&--book-1 {
		> .a-item-icon {
			&:before {
				content: '\e86d';
			}
		}
	}

	&--boots-1 {
		> .a-item-icon {
			&:before {
				content: '\e985';
			}
		}
	}

	&--boots-2 {
		> .a-item-icon {
			&:before {
				content: '\e952';
			}
		}
	}

	&--boots-3 {
		> .a-item-icon {
			&:before {
				content: '\e951';
			}
		}
	}

	&--boots-4 {
		> .a-item-icon {
			&:before {
				content: '\e950';
			}
		}
	}

	&--heart-1 {
		> .a-item-icon {
			&:before {
				content: '\e987';
			}
		}
	}

	&--key-1 {
		> .a-item-icon {
			&:before {
				content: '\e98c';
			}
		}
	}

	&--mask-1 {
		> .a-item-icon {
			&:before {
				content: '\e94d';
			}
		}
	}

	&--mask-2 {
		> .a-item-icon {
			&:before {
				content: '\e94f';
			}
		}
	}

	&--ankh-1 {
		> .a-item-icon {
			&:before {
				content: '\e9b9';
			}
		}
	}

	&--gauntlet-1 {
		> .a-item-icon {
			&:before {
				content: '\e91e';
			}
		}
	}

	&--gauntlet-2 {
		> .a-item-icon {
			&:before {
				content: '\e931';
			}
		}
	}

	&--cape-1 {
		> .a-item-icon {
			&:before {
				content: '\e956';
			}
		}
	}

	&--helmet-1 {
		> .a-item-icon {
			&:before {
				content: '\e86a';
			}
		}
	}

	&--helmet-2 {
		> .a-item-icon {
			&:before {
				content: '\e92b';
			}
		}
	}

	&--helmet-3 {
		> .a-item-icon {
			&:before {
				content: '\e927';
			}
		}
	}

	&--helmet-4 {
		> .a-item-icon {
			&:before {
				content: '\e929';
			}
		}
	}

	&--seal-1 {
		> .a-item-icon {
			&:before {
				content: '\e969';
			}
		}
	}

	&--staff-1 {
		> .a-item-icon {
			&:before {
				content: '\e963';
			}
		}
	}

	&--staff-2 {
		> .a-item-icon {
			&:before {
				content: '\e95b';
			}
		}
	}

	&--staff-3 {
		> .a-item-icon {
			&:before {
				content: '\e901';
			}
		}
	}

	&--necklace-1 {
		> .a-item-icon {
			&:before {
				content: '\e90d';
			}
		}
	}

	&--necklace-2 {
		> .a-item-icon {
			&:before {
				content: '\e904';
			}
		}
	}

	&--ring-1 {
		> .a-item-icon {
			&:before {
				content: '\e986';
			}
		}
	}

	&--ocarina-1 {
		> .a-item-icon {
			&:before {
				content: '\e93a';
			}
		}
	}

	&--fan-1 {
		> .a-item-icon {
			&:before {
				content: '\e96d';
			}
		}
	}

	&--bottle-1 {
		> .a-item-icon {
			&:before {
				content: '\e96c';
			}
		}
	}

	&--bottle-2 {
		> .a-item-icon {
			&:before {
				content: '\e970';
			}
		}
	}

	&--coil-1 {
		> .a-item-icon {
			&:before {
				content: '\e971';
			}
		}
	}

	&--stone-1 {
		> .a-item-icon {
			&:before {
				content: '\e948';
			}
		}
	}

	&--foam-1 {
		> .a-item-icon {
			&:before {
				content: '\e93d';
			}
		}
	}

	&--egg-1 {
		> .a-item-icon {
			&:before {
				content: '\e96f';
			}
		}
	}

	&--cleaver-1 {
		> .a-item-icon {
			&:before {
				content: '\e940';
			}
		}
	}

	&--sword-1 {
		> .a-item-icon {
			&:before {
				content: '\e939';
			}
		}
	}

	&--sword-2 {
		> .a-item-icon {
			&:before {
				content: '\e830';
			}
		}
	}

	&--sword-3 {
		> .a-item-icon {
			&:before {
				content: '\e959';
			}
		}
	}

	&--sword-4 {
		> .a-item-icon {
			&:before {
				content: '\e957';
			}
		}
	}

	&--sword-5 {
		> .a-item-icon {
			&:before {
				content: '\e958';
			}
		}
	}

	&--sword-6 {
		> .a-item-icon {
			&:before {
				content: '\e95f';
			}
		}
	}

	&--sword-7 {
		> .a-item-icon {
			&:before {
				content: '\e9b5';
			}
		}
	}

	&--sword-8 {
		> .a-item-icon {
			&:before {
				content: '\e843';
			}
		}
	}

	&--sword-9 {
		> .a-item-icon {
			&:before {
				content: '\e9b6';
			}
		}
	}

	&--sword-10 {
		> .a-item-icon {
			&:before {
				content: '\e960';
			}
		}
	}

	&--fish-1 {
		> .a-item-icon {
			&:before {
				content: '\e91c';
			}
		}
	}

	&--scythe-1 {
		> .a-item-icon {
			&:before {
				content: '\e918';
			}
		}
	}

	&--halberd-1 {
		> .a-item-icon {
			&:before {
				content: '\e95d';
			}
		}
	}

	&--wand-1 {
		> .a-item-icon {
			&:before {
				content: '\e937';
			}
		}
	}

	&--blade-1 {
		> .a-item-icon {
			&:before {
				content: '\e94b';
			}
		}
	}

	&--hammer-1 {
		> .a-item-icon {
			&:before {
				content: '\e95a';
			}
		}
	}

	&--hammer-2 {
		> .a-item-icon {
			&:before {
				content: '\e916';
			}
		}
	}

	&--katana-1 {
		> .a-item-icon {
			&:before {
				content: '\e868';
			}
		}
	}

	&--kunai-1 {
		> .a-item-icon {
			&:before {
				content: '\e962';
			}
		}
	}

	&--spear-1 {
		> .a-item-icon {
			&:before {
				content: '\e965';
			}
		}
	}

	&--spear-2 {
		> .a-item-icon {
			&:before {
				content: '\e9b7';
			}
		}
	}

	&--trident-1 {
		> .a-item-icon {
			&:before {
				content: '\e964';
			}
		}
	}

	&--trident-2 {
		> .a-item-icon {
			&:before {
				content: '\e967';
			}
		}
	}

	&--axe-1 {
		> .a-item-icon {
			&:before {
				content: '\e917';
			}
		}
	}

	&--lyre-1 {
		> .a-item-icon {
			&:before {
				content: '\e97d';
			}
		}
	}

	&--claw-1 {
		> .a-item-icon {
			&:before {
				content: '\e90f';
			}
		}
	}

	&--claw-2 {
		> .a-item-icon {
			&:before {
				content: "\e984";
			}
		}
	}

	&--skull-1 {
		> .a-item-icon {
			&:before {
				content: "\e96b";
			}
		}
	}

	&--shield-1 {
		> .a-item-icon {
			&:before {
				content: '\e92e';
			}
		}
	}

	&--armor-1 {
		> .a-item-icon {
			&:before {
				content: '\e935';
			}
		}
	}

	&--armor-2 {
		> .a-item-icon {
			&:before {
				content: '\e92d';
			}
		}
	}

	&--armor-3 {
		> .a-item-icon {
			&:before {
				content: '\e99f';
			}
		}
	}

	&--armor-4 {
		> .a-item-icon {
			&:before {
				content: '\e934';
			}
		}
	}

	&--scales-1 {
		> .a-item-icon {
			&:before {
				content: '\e91f';
			}
		}
	}

	&--suit-1 {
		> .a-item-icon {
			&:before {
				content: '\e99f';
			}
		}
	}

	&--vest-1 {
		> .a-item-icon {
			&:before {
				content: '\e932';
			}
		}
	}


	&--flail-1 {
		> .a-item-icon {
			&:before {
				content: '\e942';
			}
		}
	}

	&--sai-1 {
		> .a-item-icon {
			&:before {
				content: '\e922';
			}
		}
	}

	&--rod-1 {
		> .a-item-icon {
			&:before {
				content: '\e9b8';
			}
		}
	}

	&--scroll-1 {
		> .a-item-icon {
			&:before {
				content: '\e944';
			}
		}
	}

	&--dice-1 {
		> .a-item-icon {
			&:before {
				content: '\e827';
			}
		}
	}

	&--rune-1 {
		> .a-item-icon {
			&:before {
				content: '\e938';
			}
		}
	}

	&--coin-1 {
		> .a-item-icon {
			&:before {
				content: '\e96a';
			}
		}
	}

	&--goggles-1 {
		> .a-item-icon {
			&:before {
				content: '\e94c';
			}
		}
	}

	&--gem-1 {
		> .a-item-icon {
			&:before {
				content: '\e909';
			}
		}
	}

	&--tentacle-1 {
		> .a-item-icon {
			&:before {
				content: '\e973';
			}
		}
	}

	&--crown-1 {
		> .a-item-icon {
			&:before {
				content: '\e907';
			}
		}
	}

	&--hood-1 {
		> .a-item-icon {
			&:before {
				content: '\e94e';
			}
		}
	}

	&--robe-1 {
		> .a-item-icon {
			&:before {
				content: '\e955';
			}
		}
	}

	&--cloak-1 {
		> .a-item-icon {
			&:before {
				content: '\e953';
			}
		}
	}

	&--pike-1 {
		> .a-item-icon {
			&:before {
				content: '\e915';
			}
		}
	}

	&--chalice-1 {
		> .a-item-icon {
			&:before {
				content: '\e906';
			}
		}
	}

	&--puppet-1 {
		> .a-item-icon {
			&:before {
				content: '\e977';
			}
		}
	}

	&--robot-1 {
		> .a-item-icon {
			&:before {
				content: '\e97c';
			}
		}
	}

	&--pagoda-1 {
		> .a-item-icon {
			&:before {
				content: '\e9bd';
			}
		}
	}

	&--zodiac-1 {
		> .a-item-icon {
			&:before {
				content: '\e9bb';
			}
		}
	}

	&--key-1 {
		> .a-item-icon {
			&:before {
				content: '\e98c';
			}
		}
	}

	&--holy-1 {
		> .a-item-icon {
			&:before {
				content: '\e9bc';
			}
		}
	}

	&--bow-1 {
		> .a-item-icon {
			&:before {
				content: '\e914';
			}
		}
	}

	&--bow-2 {
		> .a-item-icon {
			&:before {
				content: '\e912';
			}
		}
	}

	&--bow-3 {
		> .a-item-icon {
			&:before {
				content: '\e9ba';
			}
		}
	}

	&--shuriken-1 {
		> .a-item-icon {
			&:before {
				content: '\e82c';
			}
		}
	}

	&--shuriken-2 {
		> .a-item-icon {
			&:before {
				content: '\e925';
			}
		}
	}

	&--horn-1 {
		> .a-item-icon {
			&:before {
				content: '\e99e';
			}
		}
	}

	&--horn-2 {
		> .a-item-icon {
			&:before {
				content: '\e9be';
			}
		}
	}

	&--badge-1 {
		> .a-item-icon {
			&:before {
				content: '\e83d';
			}
		}
	}

	&--dna-1 {
		> .a-item-icon {
			&:before {
				content: '\e99d';
			}
		}
	}

	&--dna-2 {
		> .a-item-icon {
			&:before {
				content: '\e898';
			}
		}
	}

	&--emerald-1 {
		> .a-item-icon {
			&:before {
				content: '\e90c';
			}
		}
	}

	&--belt-1 {
		> .a-item-icon {
			&:before {
				content: '\e982';
			}
		}
	}

	&--hat-1 {
		> .a-item-icon {
			&:before {
				content: '\e897';
			}
		}
	}

	&--hat-2 {
		> .a-item-icon {
			&:before {
				content: '\e97f';
			}
		}
	}

	&--hat-3 {
		> .a-item-icon {
			&:before {
				content: '\e97e';
			}
		}
	}

	&--shell-1 {
		> .a-item-icon {
			&:before {
				content: "\e94a";
			}
		}
	}

	&--root-1 {
		> .a-item-icon {
			&:before {
				content: "\e9a0";
			}
		}
	}


	&--sharingan-1 {
		> .a-item-icon {
			&:before {
				content: '\e83c';
			}
		}
	}

	&--mangekyo-2 {
		> .a-item-icon {
			&:before {
				content: '\e82f';
			}
		}
	}

	&--spine-1 {
		> .a-item-icon {
			&:before {
				content: '\e971';
			}
		}
	}

	&--ice-spear-1 {
		> .a-item-icon {
			&:before {
				content: '\e989';
			}
		}
	}

	&--crystal-1 {
		> .a-item-icon {
			&:before {
				content: '\e908';
			}
		}
	}

	&--byakugan-1 {
		> .a-item-icon {
			&:before {
				content: '\e99b';
			}
		}
	}

	&--mangekyo-itachi-1 {
		> .a-item-icon {
			&:before {
				content: '\e99c';
			}
		}
	}

	&--mangekyo-sasuke-1 {
		> .a-item-icon {
			&:before {
				content: '\e994';
			}
		}
	}

	&--mangekyo-obito-1 {
		> .a-item-icon {
			&:before {
				content: '\e997';
			}
		}
	}

	&--mangekyo-obito-1 {
		> .a-item-icon {
			&:before {
				content: '\e997';
			}
		}
	}

	&--mangekyo-shisui-1 {
		> .a-item-icon {
			&:before {
				content: '\e993';
			}
		}
	}

	&--mangekyo-madara-1 {
		> .a-item-icon {
			&:before {
				content: '\e9a1';
			}
		}
	}

	&--sharingan-2 {
		> .a-item-icon {
			&:before {
				content: '\e99a';
			}
		}
	}

	&--eye-1 {
		> .a-item-icon {
			&:before {
				content: '\e999';
			}
		}
	}

	&--rinnegan-1 {
		> .a-item-icon {
			&:before {
				content: '\e998';
			}
		}
	}

	&--rinne-sharingan-1 {
		> .a-item-icon {
			&:before {
				content: '\e996';
			}
		}
	}

	&--mangekyo-eternal-1 {
		> .a-item-icon {
			&:before {
				content: '\e990';
			}
		}
	}

	&--senjutsu-eye-1 {
		> .a-item-icon {
			&:before {
				content: '\e98f';
			}
		}
	}

	&--chakra-1 {
		> .a-item-icon {
			&:before {
				content: '\e856';
			}
		}
	}
}
