.h-pos {
	&--1 {
		color: $c-pos-genin-color;

		&:before {
			color: $c-pos-genin-color;
		}
	}

	&--2 {
		color: $c-pos-jounin-color;

		&:before {
			color: $c-pos-jounin-color;
		}
	}

	&--3 {
		color: $c-pos-kage-color;

		&:before {
			color: $c-pos-kage-color;
		}
	}

	&--4 {
		color: $c-pos-summon-color;

		&:before {
			color: $c-pos-summon-color;
		}
	}
}