.h-form {

	p {
		margin: 0;

		font-size: 0.65rem;
		line-height: 1.1;

		color: #e1c292;


		&.-c-warning {
			margin: -5px 0 5px 2px;

			color: #ffb327;
		}
	}


	&.-type-input {
		label {
			display: block;

			padding-left: 2px;
			margin-bottom: 3px;

			font-size: 0.8rem;
			text-shadow: 1px 1px 1px #000;

			color: #FDE4CA;
		}

		input {
			display: block;

			padding: 5px 8px;

			border: 1px solid #887957;
			border-radius: 3px;

			font-size: 0.8rem;
			line-height: 1.4;
			font-weight: bold;
			font-style: normal;
			white-space: nowrap;

			background-color: #F7F0DF;

			overflow: hidden;
		}

		select {
			display: block;

			padding: 5px 50px 5px 5px;

			border: 1px solid #887957;
			border-radius: 3px;

			font-size: 0.8rem;
			font-weight: bold;
			white-space: nowrap;

			background-color: #F7F0DF;

			overflow: hidden;
		}


		&.-size-l {
			label {
				font-size: 1rem;
			}

			input {
				padding: 10px;
			}
		}

		&.-width-long {
			input {
				min-width: 300px;
			}
		}

		&.-width-max {
			select {
				max-width: 100%;

				padding-right: 5px;
			}
		}
	}

	&.-type-checkbox {
		label {
			display: block;

			padding-left: 2px;
			margin-bottom: 3px;

			font-size: 0.8rem;
			text-shadow: 1px 1px 1px #000;

			color: #FDE4CA;
		}

		input {
			position: relative;
			bottom: 1px;

			margin-right: 2px;

			border: 1px solid #887957;
			border-radius: 3px;

			vertical-align: middle;

			background-color: #F7F0DF;
		}
	}

	&.-type-submit {
		input {
			border-radius: 6px;

			// because pseudo elements do not work on input fields
			box-shadow: 2px 2px 0 1px #85A96D inset,
						-2px -2px 0 1px #305E13 inset;

			text-align: center;
			text-shadow: 1px 1px 2px #000;

			color: #fff;
		}
	}

	&.-padding-vertical {
		padding: 10px 0;
	}

	&.-padding-box {
		padding: 10px;
	}


	// ios fix: disable zooming on input fields (?)
	@media screen and (-webkit-min-device-pixel-ratio:0) {
		select,
		textarea,
		input,
		input:focus,
		input:hover {
			font-size: 16px !important;
		}
	}
}
