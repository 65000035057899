/**
 * Negative stat color.
 */
.h-text-negative {
	@extend %h-text-negative;

	* {
		@extend %h-text-negative;
	}
}

%h-text-negative {
	color: #F2571F !important;
}





/**
 * Positive stat color.
 */
.h-text-positive {
	@extend %h-text-positive;

	* {
		@extend %h-text-positive;
	}
}

%h-text-positive {
	color: #1ccc3a !important;
}





/**
 * General colors.
 */
.h-color-arena {
	color: #A6EA7A !important;
}

.h-color-world {
	color: #eae77a !important;
}

.h-color-clan {
	color: #7ad5ea !important;
}

.h-color-diamonds {
	color: $c-resource-diamonds-color !important;
}

.h-color-chakra {
	color: #ccc !important;
}

.h-color-gold {
	color: $c-resource-gold-color !important;
}
