.h-anim {
	&--shake {
		animation-name: shake;
		animation-duration: 0.5s;
	}

	&--tada {
		animation-name: tada;
		animation-duration: 1s;
	}

	&--atk-a-0 {
		animation-name: atk-a-0;
		animation-duration: 1s;
	}

	&--atk-a-1 {
		animation-name: atk-a-1;
		animation-duration: 1s;
	}

	&--defeat-b {
		animation-name: defeat-b;
		animation-duration: 2s;
		animation-fill-mode: forwards;
	}
}