.h-scrollbar {

	&::-webkit-scrollbar-track {
		border-radius: 10px;
		background-color: #4A3722;
	}

	&::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: #9C7C57;
	}
	&::-webkit-scrollbar-corner {
		background: transparent;
	}


	&.-color-achievement {
		&::-webkit-scrollbar-track {
			background-color: #383244;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #59526b;
		}
	}
}