.h-ele {
	&--1 {
		color: #FDA02A;


		> .a-ele-icon {
			&:before  {
				content: '\e829';
			}
		}
	}

	&--2 {
		color: #60EBFF;


		> .a-ele-icon {
			&:before  {
				content: '\e808';
			}
		}
	}

	&--3 {
		color: #FBF05B;


		> .a-ele-icon {
			&:before  {
				content: '\e82d';
			}
		}
	}

	&--4 {
		color: #E29738;


		> .a-ele-icon {
			&:before  {
				content: '\e835';
			}
		}
	}

	&--5 {
		color: #94C9FF;


		> .a-ele-icon {
			&:before  {
				content: '\e81d';
			}
		}
	}
}